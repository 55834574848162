import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme.js";

import Toolbar from "@material-ui/core/Toolbar";

import Header from "./Components/Header/header.component";
import Footer from "./Components/Footer/footer.component";
import HomePage from "./Pages/Homepage/homepage.component";
import BlogPage from "./Pages/BlogPage/blogpage.component";
import GalleryPage from "./Pages/GalleryPage/gallerypage.component";
import AboutPage from "./Pages/AboutPage/aboutpage.component";
import SignInPage from "./Pages/SignInPage/SignInPage";
import EditorPage from "./Pages/EditorPage/editorpage.component";
import SignUpPage from "./Pages/SignUpPage/SignUpPage";
import Post from "./Components/BlogPost/blogpost.component.js";

import * as actions from "./Redux/actions";

const App = (props) => {
  const { getCurrentUser } = props;
  if (window.location.href.match("#")) {
    window.location.href = window.location.href.replace(/#.*/, "");
  }
  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/login" component={SignInPage} />
          <Route exact path="/signup" component={SignUpPage} />
          <Route exact path="/blog" component={BlogPage} />
          <Route path="/blog/post/:id" component={Post} />
          <Route exact path="/gallery" component={GalleryPage} />
          <Route exact path="/about" component={AboutPage} />
          <Route
            exact
            path="/blog/new"
            currentUser={props.currentUser}
            component={EditorPage}
          />
          <Redirect to="/" />
        </Switch>
        <Toolbar />
        <Footer />
      </div>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};
export default connect(mapStateToProps, actions)(App);
