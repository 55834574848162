import React, { Component } from "react";

import GalleryPreview from "../../Components/GalleryPreview/gallerypreview.component";
import Spinner from "../../Components/Spinner/spinner.component";
import API from "../../Components/Api/api.component";

class GalleryComponent extends Component {
  state = {
    gallery: [],
    isLoading: true,
  };

  componentDidMount() {
    this.fetchGallery();
  }

  async fetchGallery() {
    // Where we're fetching data from
    const response = await API.get("/gallery");
    // ...then we update the gallery state

    this.setState({
      gallery: response.data,
      isLoading: false,
    });
    // Catch any errors we hit and update the app
    //.catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { gallery, isLoading } = this.state;

    return (
      <div className="gallery-page">
        {!isLoading ? <GalleryPreview gallery={gallery} /> : <Spinner />}
      </div>
    );
  }
}

export default GalleryComponent;
