import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    marginTop: 20,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
    justifyContent: "right",
  },
  imageCR: {
    textDecoration: "none",
    cursor: "pointer",
    fontSize: ".75rem",
  },
  crModal: {
    marginTop: "25vh",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50vw",
    backgroundColor: theme.palette.background.paper,
    height: "22vh",
    alignItems: "center",
    borderStyle: "solid",
    border: "1px 1px 1px black",
    borderRadius: "10px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [copyShow, setCopyShow] = useState(false);
  const handleCopyrightOpen = () => {
    setCopyShow(true);
  };
  const handleCopyrightClose = () => {
    setCopyShow(false);
  };

  return (
    <div className={classes.stickToBottom}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <Typography variant="subtitle1" align="center">
              <div>
                © {currentYear}{" "}
                <Link
                  href="https://wwww.drivelikeyastoleit.com"
                  color="inherit">
                  <em>db</em>designs
                </Link>
                <Typography
                  className={classes.imageCR}
                  align="center"
                  onClick={() => {
                    handleCopyrightOpen();
                  }}>
                  image copyright information
                </Typography>
              </div>
            </Typography>
          </Paper>
        </Grid>
        <Modal
          className={classes.crModal}
          open={copyShow}
          onClose={handleCopyrightClose}
          closeAfterTransition>
          <Fade in={copyShow}>
            <div style={{ padding: "5px", outline: 0 }}>
              <h2> CopyRight Information</h2>
              <p>
                © David W. Beidle II and drivelikeyastoleit.com, {currentYear}.
                Unauthorized use and/or duplication of the images or content
                contained within without expressed and written permission from
                this site's owner is strictly prohibited.{" "}
              </p>
              <p>
                The Serenity Circle Logo on this site is copyright 2002-2005
                Mutant Enemy, Inc., Universal Pictures, and 20th Century Fox and
                is used under the fair use doctrine.{" "}
              </p>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </div>
  );
};

export default Footer;
