import axios from "axios";

const apiURL = "/api";
const API = axios.create({
  withCredentials: true,
  baseURL: apiURL,
  headers: {
    dlysi: process.env.REACT_APP_DLYSI,
  },
});
export default API;
