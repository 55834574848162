import { createMuiTheme } from "@material-ui/core/styles";
const customTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  palette: {
    common: { black: "#000", white: "#fff" },
    background: {
      paper: "rgba(50, 49, 49, 1)",
      modal: "rgba(50, 49, 49, .25)",
      default: "rgba(0, 0, 0, 1)",
    },
    primary: {
      light: "rgba(62, 63, 72, 1)",
      main: "rgba(0, 1, 9, 1)",
      dark: "#303f9f",
      contrastText: "rgba(211, 211, 211, 1)",
    },
    secondary: {
      light: "rgba(142, 140, 140, 1)",
      main: "rgba(121, 120, 120, 1)",
      dark: "rgba(86, 84, 84, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(120, 120, 120, 1)",
      secondary: "rgba(88,88,88, 1)",
      disabled: "rgba(161, 104, 104, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  overrides: {
    MuiMobileStepper: {
      dots: {
        //backgroundColor: "rgba(50, 49, 49, .5)",
      },
      dot: {
        backgroundColor: "rgba(252, 136, 3, 0.5)",
        border: "1px solid black",
      },
      dotActive: {
        backgroundColor: "rgba(255,0,0,1)",
        border: "1px solid black",
      },
    },
  },
});

export default customTheme;
