import React, { useEffect, useState } from "react";
import "./galleryviewer.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Stepper from "../Stepper/stepper.component";

const GalleryViewer = ({ name, images }) => {
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState(images[index].imgUrl);
  useEffect(() => {
    setImage(images[index].imgUrl);
  }, [index, images]);

  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
         the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
         effect to only run when the component mounts, and not each time it updates.
         We only want the listener to be added once */
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const onClickNext = () => {
    if (index + 1 === images.length) {
      setIndex(0);
      setActiveStep(0);
    } else {
      setIndex(index + 1);
      setActiveStep(index + 1);
    }
    setImage(images[index].imgUrl);
  };
  const onClickPrevious = () => {
    if (index - 1 === -1) {
      setIndex(images.length - 1);
      setActiveStep(images.length - 1);
    } else {
      setIndex(index - 1);
      setActiveStep(index - 1);
    }
    setImage(images[index].imgUrl);
  };

  return (
    <div>
      <div
        style={{
          flexDirection: "row",
          transition: "all 0s ease 0s",
          direction: "ltr",
          display: "flex",
          willChange: "transform",
          transform: "translate(0%, 0px)",
          alignItems: "center",
          justify: "center",
          marginTop: "5vh",
        }}>
        <ArrowBackIosIcon
          fontSize={width < breakpoint ? "small" : "large"}
          style={{ color: "#fff" }}
          onClick={onClickPrevious}
        />

        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minWidth: `${width < breakpoint ? "90vw" : "80vw"}`,
            minHeight: `${width < breakpoint ? "85vh" : "80vh"}`,
            color: "#fff",
          }}></div>
        <ArrowForwardIosIcon
          fontSize={width < breakpoint ? "small" : "large"}
          style={{ color: "#fff", paddingLeft: 5 }}
          onClick={onClickNext}
        />
      </div>
      <div>
        <Stepper steps={maxSteps} activeStep={activeStep} />
      </div>
    </div>
  );
};

export default GalleryViewer;
