import API from "../../Components/Api/api.component";
import {
  CREATE_POST,
  GET_POSTS,
  GET_POST,
  GET_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  LOGIN_ERROR,
  POST_COMMENT,
} from "./types";
import { history } from "../../index";

export const createPost = (body) => async (dispatch) => {
  const response = await API({
    method: "post",
    url: "/post/new",
    data: body,
  });
  history.push("/blog");
  dispatch({ type: CREATE_POST, payload: response.data });
};

export const getBlogPosts = () => async (dispatch) => {
  const response = await API.get("/posts").catch((err) => console.error(err));
  dispatch({ type: GET_POSTS, payload: response.data });
};

export const getBlogPost = (id) => async (dispatch) => {
  const resp = await API({
    method: "post",
    url: "/post",
    data: { id: id },
  }).catch((err) => console.error(err));
  dispatch({ type: GET_POST, payload: resp.data });
};

export const postComment = (props) => async (dispatch) => {
  const resp = await API({
    method: "post",
    url: "/post/comment",
    data: props,
  }).catch((err) => console.error(err));
  dispatch({ type: POST_COMMENT, payload: resp.data });
};

export const loginUser = (email, password) => async (dispatch) => {
  const res = await API({
    method: "post",
    url: "/auth/login",
    data: { email, password },
  }).catch((err) => {
    dispatch({ type: LOGIN_ERROR, payload: err });
  });
  if (res) {
    dispatch({ type: LOGIN_USER, payload: res.data });
  }
};

export const registerUser = (details) => async (dispatch) => {
  const { email, firstName, lastName, password, cPassword } = details;
  if (!email || !firstName || !lastName || !password || !cPassword) {
    dispatch({ type: REGISTER_USER_ERROR, payload: "Missing Required Field" });
  } else if (password !== cPassword) {
    dispatch({ type: REGISTER_USER_ERROR, payload: "Passwords Don't Match" });
  } else {
    const response = await API({
      method: "post",
      url: "/auth/signup",
      data: { email, firstName, lastName, password, cPassword },
    }).catch((err) => {
      dispatch({ type: REGISTER_USER_ERROR, payload: err });
    });
    if (response) {
      dispatch({ type: REGISTER_USER, payload: response.data });
    }
  }
};

export const handleLogout = () => async (dispatch) => {
  await API("/auth/logout");
  dispatch({ type: LOGOUT_USER, payload: null });
  history.push("/");
};

export const getCurrentUser = () => async (dispatch) => {
  const res = await API.get("/user/current_user").catch((err) =>
    console.error(err)
  );
  if (typeof res === "undefined") {
    dispatch({ type: GET_USER, payload: null });
  } else {
    dispatch({ type: GET_USER, payload: res.data });
  }
};

export const getDate = (date) => (dispatch) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dt = new Date(date).toLocaleString();
  const month = months[new Date(dt).getMonth()];
  const day = new Date(dt).getDate();
  const year = new Date(dt).getFullYear();
  const hr = new Date(dt).getHours();
  const ampm = hr >= 12 ? "PM" : "AM";
  const minutes = new Date(dt).getMinutes();
  const hour =
    ampm === "PM" && hr % 12 === 0
      ? 12
      : ampm === "AM" && hr % 12 === 0
      ? "00"
      : hr % 12;

  const fullDate = {
    month,
    day,
    year,
    hour,
    minutes,
    ampm,
  };

  return fullDate;
};
