import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import * as actions from "../../Redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: 0,
  },
  stickToTop: {
    width: "100%",
    position: "fixed",
    top: 0,
    marginTop: 0,
  },

  button: {
    color: "rgb(255,0,0)",
    padding: 5,
    "&:hover": {
      color: "grey",
      backgroundColor: "rgba(252, 136, 3, 0.5)",
      border: "1px solid grey",
      borderRadius: 25,
    },
  },
  img: {
    width: "25%",
    padding: 0,
    overflow: "visible",
  },
  menu: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignContent: "center",
  },
  menuPaper: {
    background: theme.palette.background.paper,
    border: "1px solid red",
  },
  menuItem: {
    minWidth: 120,
    padding: 5,
    "&:hover": {
      color: "grey",
      backgroundColor: "rgba(252, 136, 3, 0.5)",
    },
  },
  link: {
    color: "rgb(255,0,0)",
    textDecoration: "none",
    cursor: "pointer",
  },
}));

const Header = (props) => {
  const { currentUser, handleLogout } = props;
  const classes = useStyles();

  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window.innerWidth);
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, [setWidth]);

  const CollapsedMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Fragment>
        <MenuIcon
          className={classes.button}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem className={classes.menuItem}>
            <Link className={classes.link} onClick={handleClose} to={"/blog"}>
              BLOG
            </Link>{" "}
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <Link
              className={classes.link}
              onClick={handleClose}
              to={"/gallery"}>
              GALLERY
            </Link>
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <Link className={classes.link} onClick={handleClose} to={"/about"}>
              ABOUT
            </Link>
          </MenuItem>

          {currentUser ? (
            <MenuItem className={classes.menuItem}>
              <Link
                className={classes.link}
                to=""
                onClick={() => handleLogout()}>
                SIGN OUT
              </Link>
            </MenuItem>
          ) : (
            <MenuItem className={classes.menuItem}>
              <Link className={classes.link} onClick={handleClose} to="/login">
                SIGN IN
              </Link>
            </MenuItem>
          )}
        </Menu>
      </Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar>
        <Toolbar>
          <Link to="/">
            <img
              className={classes.img}
              src="/site_images/dlysi-logo.png"
              alt="logo"
            />
          </Link>
          {width < 600 ? (
            <CollapsedMenu />
          ) : (
            <Fragment>
              <Button
                className={classes.button}
                color="secondary"
                component={Link}
                to={"/blog"}>
                Blog
              </Button>
              <Button
                className={classes.button}
                style={{ minWidth: "80px" }}
                color="secondary"
                component={Link}
                to={"/gallery"}>
                Gallery
              </Button>
              <Button
                className={classes.button}
                color="secondary"
                component={Link}
                to={"/about"}>
                About
              </Button>

              {currentUser ? (
                <Button
                  className={classes.button}
                  style={{ minWidth: "90px" }}
                  color="secondary"
                  onClick={() => handleLogout()}>
                  SIGN OUT
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  style={{ minWidth: "70px" }}
                  component={Link}
                  to="/login">
                  SIGN IN
                </Button>
              )}
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Toolbar />
      <Toolbar />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, actions)(Header);
