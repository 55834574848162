import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40vw",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: "5px 0px",
    maxWidth: "40vw",
  },
  button: {
    marginTop: "15px",
    float: "right",
    color: "darkgrey",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: 100,
    height: 50,
    fontSize: "1.3rem",
    borderRadius: "25px",
    border: "none",
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  selectEmpty: {
    marginTop: "theme.spacing(2)",
  },
  inputLabel: {
    color: "#fff",
    "&.Mui-focused": {
      color: "orange",
    },
  },
  errorMsg: {
    color: "red",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.5em",
    backgroundColor: "rgba(255,0,0,0.25)",
    border: "1px solid red",
    borderRadius: "3px",
  },
  customIcon: {
    position: "absolute",
    left: "auto",
    right: "10px",
    textIndent: "32px",
    top: "20px",
  },
  checkSpan: {
    position: "absolute",
    right: "auto",
    left: "10px",
    top: "30px",
  },
  check: {
    color: "green",
  },
  disclaimer: {
    marginTop: 50,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: ".75em",
    maxWidth: "60vw",
  },
}));
const useOutlinedStyles = makeStyles((theme) => ({
  root: {
    width: "40vw",
    "& $notchedOutline": {
      borderColor: "red",
    },
    "&:hover $notchedOutline": {
      borderColor: "blue",
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const SignUp = (props) => {
  const { registerUser, error, redirect } = props;
  const classes = useStyles();
  const outlineClasses = useOutlinedStyles();

  const [signUpDetails, setSignUpDetails] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    cPassword: "",
  });
  const [revealPassword, setRevealPassword] = useState(false);
  const [revealPassword1, setRevealPassword1] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSignUpDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };
  const togglePassword1 = () => {
    setRevealPassword1(!revealPassword1);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validateName = (name) => {
    const re = /^[A-Z][a-zA-Z\s]+$/;

    if (!re.test(name)) {
      return false;
    } else {
      return true;
    }
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}).*$/;
    if (!re.test(password)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await registerUser(signUpDetails).then(
      setSignUpDetails({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        cPassword: "",
      })
    );
  };
  return (
    <div>
      {redirect ? <Redirect to="/" /> : null}

      <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        {error ? <div className={classes.errorMsg}>{error} </div> : null}
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="email-input" className={classes.inputLabel}>
              E-mail{" "}
            </InputLabel>
            <OutlinedInput
              id="email-input"
              value={signUpDetails.email}
              onChange={handleChange}
              type="email"
              label="email"
              classes={outlineClasses}
              name="email"
              fullWidth
            />
            <span className={classes.checkSpan}>
              {validateEmail(signUpDetails.email) ? (
                <CheckIcon className={classes.check} />
              ) : null}
            </span>
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="firstName-input"
              className={classes.inputLabel}>
              First Name
            </InputLabel>
            <OutlinedInput
              id="firstName-input"
              value={signUpDetails.firstName}
              onChange={handleChange}
              type="text"
              label="firstName"
              classes={outlineClasses}
              name="firstName"
              fullWidth
            />
            <span className={classes.checkSpan}>
              {validateName(signUpDetails.firstName) ? (
                <CheckIcon className={classes.check} />
              ) : null}
            </span>
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="lastName-input" className={classes.inputLabel}>
              Last Name
            </InputLabel>
            <OutlinedInput
              id="lastName-input"
              value={signUpDetails.lastName}
              onChange={handleChange}
              type="text"
              label="lastName"
              classes={outlineClasses}
              name="lastName"
              fullWidth
            />
            <span className={classes.checkSpan}>
              {validateName(signUpDetails.lastName) ? (
                <CheckIcon className={classes.check} />
              ) : null}
            </span>
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="password-input" className={classes.inputLabel}>
              Password
            </InputLabel>
            <OutlinedInput
              id="password-input"
              value={signUpDetails.password}
              onChange={handleChange}
              label="password"
              classes={outlineClasses}
              name="password"
              type={revealPassword ? "text" : "password"}
            />
            <span onClick={togglePassword}>
              {revealPassword ? (
                <Visibility className={classes.customIcon} />
              ) : (
                <VisibilityOff className={classes.customIcon} />
              )}
            </span>
            <span className={classes.checkSpan}>
              {validatePassword(signUpDetails.password) ? (
                <CheckIcon className={classes.check} />
              ) : null}
            </span>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              htmlFor="cPassword-input"
              className={classes.inputLabel}>
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="cPassword-input"
              value={signUpDetails.cPassword}
              onChange={handleChange}
              label="cPassword"
              classes={outlineClasses}
              name="cPassword"
              type={revealPassword1 ? "text" : "password"}
            />
            <span onClick={togglePassword1}>
              {revealPassword ? (
                <Visibility className={classes.customIcon} />
              ) : (
                <VisibilityOff className={classes.customIcon} />
              )}
            </span>
            <span className={classes.checkSpan}>
              {signUpDetails.password === signUpDetails.cPassword &&
              signUpDetails.cPassword !== "" ? (
                <CheckIcon className={classes.check} />
              ) : null}
            </span>
          </FormControl>
        </div>

        <div>
          <div>ALL FIELDS REQUIRED</div>
          <button className={classes.button}>Sign Up</button>{" "}
        </div>
        <br />
        <span className={classes.disclaimer}>
          Password must contain the following: <br />
          at least 1 lowercase character <br />
          at least 1 uppercase character
          <br />
          at least 1 number <br />
          at least one special character (!@#$%^&*) <br />
          and must be eight characters or longer in length
        </span>
      </form>
      <p className={classes.disclaimer}>
        This computer system is for authorized use only. Users have no explicit
        or implicit expectation of privacy. Any or all uses of this system and
        all data on this system may be intercepted, monitored, recorded, copied,
        audited, inspected, and disclosed to law enforcement personnel, as well
        as authorized officials of other agencies. By using this system, the
        user consents to such disclosure at the discretion of authorized site
        personnel. Unauthorized or improper use of this system may result in
        legal action, civil and criminal penalties. By continuing to use this
        system you indicate your awareness of and consent to these terms and
        conditions of use.{" "}
        <strong>
          STOP IMMEDIATELY!!! if you do not agree to the conditions stated in
          this warning.
        </strong>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    error: state.auth.signUpError,
    redirect: state.auth.signUpRedirect,
  };
};

export default connect(mapStateToProps, actions)(SignUp);
