import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    width: "60vw",
    height: "25vh",
  },
  content: {
    justify: "center",
    alignItems: "left",
  },
}));

function AboutPage() {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.content}>
        <h1> All About DriveLikeYaStoleIt</h1>
        <br />
        <p>
          Hi there. Welcome to my little piece of the internet. I'm Dave aka
          DriveLikeYaStoleIt. I have been around these parts for many years and
          have had a website of some form for most of them. This is my second
          iteration of my site on this particular server. This one, I am
          building by hand.
        </p>
        <p>
          I am a Network Engineer by trade and have started to move to the
          darkside of web development. I can do some coding in python which some
          of the backend of this site will eventually run on. I am in the
          process of learning ReactJS which is what your device is using to
          display this site right now. Enough about this site, let's get back to
          me.
        </p>
        <p>
          I am into Automobiles. I love all things on wheels. My first car was a
          1950 Chevy Fleetline. Some people would call it a "fast-back" in
          todays verbiage. Boy was I sad to see it go... I currently own a GMC
          Denali and a 04 Acura RSX Type-S. I love them both. I am from
          Louisiana originally but currently live in the St. Louis, MO area. I
          try to make it out to as many car shows as I can in a year to
          photograph and just enjoy the different cars/trucks/bikes that I can
          get my eyes on.
        </p>
        <p>
          I will be sharing some of that photography with you here on this site
          as it is one of the other things I enjoy doing. Some of the other
          things I like are Sci-Fi shows like Firefly/Serenity, Star Trek, and
          Star Wars. Then there are the NCIS brands of shows. I was so sad when
          Abby(Pauley Perrette) left the show. There are quite a few other shows
          I like but I'll save you all from having to read through the whole
          list.
        </p>
        <p>
          I am a Railfan. Not many of us left in this world. Nothing like
          chasing an old steam powered beast just to get photos of it at
          different spots. I even like the older diesel era of locomotives. My
          favorite Railroads are the Atichson Topeka and Santa Fe(AT&SF) and the
          Illinois Central Gulf (ICG).
        </p>
        <p>
          As you can tell I geek out about quite a few things but I will leave
          you here and just say thanks for stopping by and I hope you enjoyed
          your stay here on my small piece of digital space.
        </p>
        <p className="signature">--Dave</p>
      </div>
      <Toolbar />
      <Toolbar />
    </Container>
  );
}

export default AboutPage;
