import {
  GET_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  LOGIN_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  user: null,
  loginError: null,
  loginRedirect: false,
  signUpRedirect: false,
  signUpError: null,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        user: action.payload,
        loginRedirect: false,
        loginError: null,
      };
    case LOGIN_ERROR:
      return { ...state, loginError: action.payload, loginRedirect: false };
    case LOGIN_USER:
      return { ...state, user: action.payload, loginRedirect: true };
    case GET_USER:
      return { ...state, user: action.payload };
    case REGISTER_USER:
      return { ...state, user: action.payload, signUpRedirect: false };
    case REGISTER_USER_ERROR:
      return { ...state, signUpError: action.payload };
    default:
      return state;
  }
};

export default authReducer;
