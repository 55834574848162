import React from "react";

import Container from "@material-ui/core/Container";

const Homepage = () => {
  const useStyles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    container: {
      width: "50vw",
      height: "25vh",
    },
    content: {
      justify: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <div className={classes.content}>
        <h3>
          Welcome to my slice of the web. I hope you find Serenity and Peace in
          your journey!
        </h3>
        <p>
          This is my little place on the web to have fun and show off some
          stuff.
        </p>
        <p>
          The only thing currently to see though is the progress I am making on
          building this site.
        </p>
        <img
          style={{
            maxHeight: "45vh",
            maxWidth: "45vh",
          }}
          alt="welcome"
          src="/site_images/SerenityLogo.png"
        />
      </div>
    </Container>
  );
};

export default Homepage;
