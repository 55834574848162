import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import TextEditor from "../../Components/TextEditor/TextEditor";
import Spinner from "../../Components/Spinner/spinner2.component";
import API from "../../Components/Api/api.component";

class EditorPage extends Component {
  state = {
    auth: {},
    loading: true,
  };

  componentDidMount() {
    this.getUser();
  }

  async getUser() {
    await API.get("/user/current_user").then((response) => {
      this.setState({ auth: response.data, loading: false });
    });
  }
  render() {
    const { auth, loading } = this.state;
    return (
      <div>
        {loading ? (
          <Spinner />
        ) : !loading && auth.owner ? (
          <div
            style={{
              height: "50vh",
              width: "50vw",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            <TextEditor />
          </div>
        ) : (
          <Redirect to="/blog" />
        )}
      </div>
    );
  }
}

export default EditorPage;
