import React, { useEffect } from "react";

import GalleryViewer from "../GalleryViewer/galleryviewer.component";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.secondary.contrastText,
    position: "absolute",
    width: "95vw",
    height: "95vh",
    backgroundColor: "rgba(0, 0, 0, .9)",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginTop: "3vh",
    marginLeft: "5vw",
    marginBottom: 0,
    outline: "none",
  },
  gridRight: {
    marginLeft: "auto",
  },
}));

const GalleryModal = ({ open, details, handleClose }) => {
  const classes = useStyles();
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);
  const body = (
    <div
      className={classes.paper}
      style={{
        padding: `${width < breakpoint ? "0" : classes.paper.padding}`,
        marginLeft: `${width < breakpoint ? "0" : classes.paper.marginLeft}`,
      }}>
      <Grid
        className="close"
        container
        alignitems="flex-start"
        justify="flex-end"
        direction="row">
        <Grid>{details.name}</Grid>
        <Grid className={classes.gridRight}>
          <CloseIcon
            className="closeIcon"
            onClick={handleClose}
            cursor="pointer"
          />
        </Grid>
      </Grid>
      <GalleryViewer
        key={details.id}
        handleClose={handleClose}
        name={details.name}
        images={details.images}
      />
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Photo Gallery Modal"
        aria-describedby={details.name}>
        {body}
      </Modal>
    </div>
  );
};

export default GalleryModal;
