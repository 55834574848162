import React, { Fragment } from "react";
import * as actions from "../../Redux/actions";
import { connect } from "react-redux";

const Comments = (props) => {
  const { comments, getDate } = props;

  return (
    <Fragment>
      {comments.length >= 1
        ? comments.map(
            ({
              _id,
              userName,
              commentBody,
              createdAt,
              date = getDate(createdAt),
            }) => {
              return (
                <div
                  key={_id}
                  style={{
                    width: "100%",
                    border: "1px solid rgba(161, 104, 104, 0.38)",
                    padding: "10px",
                    color: "rgba(255, 160, 18, 0.50)",
                    margin: "5px",
                  }}>
                  <span style={{ color: "rgba(180, 180, 180, .95)" }}>
                    {userName}
                  </span>
                  <span
                    style={{
                      float: "right",
                      color: "rgba(252, 136, 3,.65)",
                      fontSize: ".83em",
                    }}>
                    {" "}
                    {date.day} {date.month} {date.year} {date.hour}:
                    {date.minutes}
                    {date.ampm}
                  </span>
                  <br />
                  <br />
                  {commentBody}
                </div>
              );
            }
          )
        : null}
    </Fragment>
  );
};

export default connect(null, actions)(Comments);
