import { combineReducers } from "redux";
import createPostReducer from "./createPostReducer";
import getPostsReducer from "./getPostsReducer";
import authReducer from "./auth.Reducer";

export default combineReducers({
  newPost: createPostReducer,
  blog: getPostsReducer,
  auth: authReducer,
});
