import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaginationItem-page": { color: "rgb(255,0,0)" },
    "& .Mui-selected": {
      color: "grey",
      backgroundColor: "rgba(252, 136, 3, 0.4)",
      border: "1px solid grey",
      borderRadius: "50",
      "&:hover": {
        backgroundColor: "rgba(252, 136, 3, 1)",
      },
    },
  },

  readmore: {
    color: "#fc8803",
    float: "right",
    cursor: "pointer",
  },
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
  pages: {
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgb(255,255,255)",
    },
  },
  "Mui-selected": {
    backgroundColor: "#fc8803",
  },
}));

const BlogPreview = (props) => {
  const classes = useStyles();

  //const handleClick = (id) => {};
  const { posts, getDate } = props;
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  useEffect(() => {
    setNoOfPages(Math.ceil(posts.length / itemsPerPage));
  }, [posts]);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={3}
        justify="flex-start"
        alignItems="flex-start">
        {posts.length >= 1
          ? posts
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map(
                ({
                  _id,
                  createdAt,
                  title,
                  blurb,
                  date = getDate(createdAt),
                }) => (
                  <Grid item sm={10} md={10} lg={10} key={_id}>
                    <div>
                      <h2>{title}</h2>
                      <h3>
                        {date.day} {date.month} {date.year}
                      </h3>
                      <p>
                        {blurb}{" "}
                        <span className={classes.readmore}>
                          <a
                            href={`/blog/post/${_id}`}
                            className={classes.readmore}>
                            Click to read more...{" "}
                          </a>
                        </span>
                      </p>
                    </div>
                  </Grid>
                )
              )
          : null}
      </Grid>
      <Box component="span" className={classes.root}>
        <Pagination
          count={noOfPages}
          page={page}
          onChange={handlePageChange}
          defaultPage={1}
          size="large"
          showFirstButton
          showLastButton
          classes={{
            ul: classes.paginator,
          }}
        />
      </Box>
    </div>
  );
};

export default connect(null, actions)(BlogPreview);
