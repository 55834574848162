import React, { Component } from "react";

import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./TextEditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.styles.css";
import { connect } from "react-redux";
import { createPost } from "../../Redux/actions";
import Toolbar from "@material-ui/core/Toolbar";

class TextEditor extends Component {
  state = {
    value: null,
    title: "",
    blurb: "",
  };
  handleEditorChange = (value) => {
    this.setState({ value: value });
    const doc = new DOMParser().parseFromString(this.state.value, "text/html");
    this.setState({ blurb: this.createBlurb(doc.body.textContent) });
  };
  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  createBlurb = (input) => {
    const length = 213;
    const trimmedString =
      input.length >= length ? input.substring(0, length - 3) + "..." : input;
    return trimmedString;
  };

  handleSubmit = () => {
    const userId = "5f8b6c45e6daac768533c564";
    const body = {
      userId: userId,
      title: this.state.title,
      postBody: this.state.value,
      blurb: this.state.blurb,
    };
    this.props.createPost(body);
  };
  render() {
    return (
      <div className="text-editor">
        <div style={{ border: "2px solid" }}>
          <h3>
            Post Title:{" "}
            <input
              className="input-field"
              type="text"
              placeholder="Enter a title"
              name="title"
              onChange={this.handleTitleChange}
            />
          </h3>
        </div>
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={this.state.value}
          onChange={this.handleEditorChange}
          placeholder={"What do you want to share today?"}
          modules={modules}
          formats={formats}
        />

        <button className="submit-button" onClick={this.handleSubmit}>
          Submit
        </button>
        <Toolbar />
        <Toolbar />
      </div>
    );
  }
}

export default connect(null, { createPost })(TextEditor);
