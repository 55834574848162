import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40vw",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    "@media": {},
  },
  formControl: {
    margin: "5px 0px",
    maxWidth: "40vw",
  },
  button: {
    marginTop: "15px",
    float: "right",
    color: "darkgrey",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: 100,
    height: 50,
    fontSize: "1.3rem",
    borderRadius: "25px",
    border: "none",
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  button2: {
    marginTop: "15px",
    float: "left",
    color: "darkgrey",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: 100,
    height: 50,
    fontSize: "1.3rem",
    borderRadius: "25px",
    border: "none",
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  button_g: {
    display: "flex",
    backgroundColor: "#4285F4",
    width: 210,
    height: 30,
    color: "#fff",
    justifyContent: "center",
    border: "1px solid black",
    borderRadius: "25px",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: 900,
    left: 10,
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  button_f: {
    display: "flex",
    backgroundColor: "#1877f2",
    width: 210,
    height: 30,
    color: "#fff",
    justifyContent: "center",
    border: "1px solid #1877f2",
    borderRadius: "25px",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: 900,
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  selectEmpty: {
    marginTop: "theme.spacing(2)",
  },
  inputLabel: {
    color: "#fff",
    "&.Mui-focused": {
      color: "orange",
    },
  },
  errorMsg: {
    color: "red",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.5em",
    backgroundColor: "rgba(255,0,0,0.25)",
    border: "1px solid red",
    borderRadius: "3px",
  },
  customIcon: {
    position: "absolute",
    left: "auto",
    right: "10px",
    textIndent: "32px",
    top: "20px",
  },
  disclaimer: {
    marginTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: ".75em",
    maxWidth: "60vw",
  },
  a: {
    textDecoration: "none",
    cursor: "pointer",
    color: "darkgrey",
  },
  a2: {
    display: "flex",
    margin: "auto",
    paddingRight: 10,
    textDecoration: "none",
    cursor: "pointer",
    color: "white",
  },
}));
const useOutlinedStyles = makeStyles((theme) => ({
  root: {
    width: "40vw",
    "& $notchedOutline": {
      borderColor: "red",
    },
    "&:hover $notchedOutline": {
      borderColor: "blue",
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const SignInPage = (props) => {
  const { loginUser, error, redirect } = props;
  const classes = useStyles();
  const outlineClasses = useOutlinedStyles();

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [revealPassword, setRevealPassword] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLoginDetails((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };

  const handleSubmit = async (e) => {
    const { email, password } = loginDetails;
    e.preventDefault();
    await loginUser(email, password).then(
      setLoginDetails({ email: "", password: "" })
    );
  };

  return (
    <div>
      {redirect ? <Redirect to="/" /> : null}

      <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        {error ? <div className={classes.errorMsg}>{error} </div> : null}
        <div>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            lg={12}
            md={6}
            xs={8}>
            <InputLabel htmlFor="email-input" className={classes.inputLabel}>
              E-mail
            </InputLabel>
            <OutlinedInput
              id="email-input"
              value={loginDetails.email}
              onChange={handleChange}
              type="email"
              label="email"
              classes={outlineClasses}
              name="email"
              fullWidth
            />
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="password-input" className={classes.inputLabel}>
              Password
            </InputLabel>
            <OutlinedInput
              id="password-input"
              value={loginDetails.password}
              onChange={handleChange}
              label="password"
              classes={outlineClasses}
              name="password"
              type={revealPassword ? "text" : "password"}
            />
            <span onClick={togglePassword}>
              {revealPassword ? (
                <Visibility className={classes.customIcon} />
              ) : (
                <VisibilityOff className={classes.customIcon} />
              )}
            </span>
          </FormControl>
        </div>

        <div>
          <div>
            Don't have an account?
            <br />
          </div>
          <button className={classes.button}>Sign In</button>
          <button className={classes.button2}>
            <a href="/signup" className={classes.a}>
              SignUp
            </a>
          </button>
        </div>
      </form>
      <br />
      <br />
      <br />
      <br />
      <div className={classes.root}>
        <div className={classes.button_f}>
          <img
            style={{ width: 30, marginRight: 15 }}
            src="site_images/f-logo.png"
            alt="login with Facebook"
          />
          <a
            href="https://www.drivelikeyastoleit.com/api/auth/facebook"
            alt="login with facebook"
            className={classes.a2}>
            <span style={{ display: "flex", margin: "auto" }}>
              Sign in with Facebook
            </span>
          </a>
        </div>
        <br />
        <div className={classes.button_g}>
          <img
            style={{ width: 30, marginRight: 30 }}
            src="site_images/g-logo.png"
            alt="login with Google"
          />
          <a
            href="https://www.drivelikeyastoleit.com/api/auth/google"
            alt="login with google"
            className={classes.a2}>
            <span style={{ display: "flex", margin: "auto" }}>
              Sign in with Google
            </span>
          </a>
        </div>
      </div>
      <p className={classes.disclaimer}>
        This computer system is for authorized use only. Users have no explicit
        or implicit expectation of privacy. Any or all uses of this system and
        all data on this system may be intercepted, monitored, recorded, copied,
        audited, inspected, and disclosed to law enforcement personnel, as well
        as authorized officials of other agencies. The data captured by this
        application however, will not be sold by the site operator. By using
        this system, the user consents to such disclosure at the discretion of
        authorized site personnel. Unauthorized or improper use of this system
        may result in legal action, civil and criminal penalties. By continuing
        to use this system you indicate your awareness of and consent to these
        terms and conditions of use. <br />
        <strong>
          STOP IMMEDIATELY!!! if you do not agree to the conditions stated in
          this warning.
        </strong>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    error: state.auth.loginError,
    redirect: state.auth.loginRedirect,
  };
};

export default connect(mapStateToProps, actions)(SignInPage);
