import React, { useState } from "react";

import GalleryModal from "../GalleryModal/galleryModal.component";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: theme.palette.background,
    flexGrow: 1,
    marginLeft: 50,
    marginRight: 50,
  },
  cardGrid: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    maxWidth: 400,
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    minWidth: 300,
    maxWidth: 400,
    margin: 5,
    padding: theme.spacing(1),
    color: "#D3D3D3",
  },
  gridList: {
    margin: 50,
  },
  galleryName: {
    color: "#fc8803",
  },
}));

const CollectionPreview = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ id: "", name: "", images: [] });

  // const handleModalOpen = () => {
  //   setOpen(true);
  // };

  const handleModalClose = () => {
    setOpen(false);
  };

  const galleryClickHandler = (id, name, images, handleModalClose) => {
    setModalInfo({ id, name, images });
    if (!open) {
      setOpen(true);
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {props.gallery.map(({ id, images, name }) => (
          <Grid item xs key={id}>
            <Card className={classes.cardContent}>
              <CardActionArea>
                <CardMedia
                  onClick={() => {
                    galleryClickHandler(id, name, images, handleModalClose);
                  }}
                  id={id}
                  component="img"
                  height="180"
                  image={images[0].imgUrl}
                />
                <CardContent>
                  <Typography
                    className={classes.galleryName}
                    gutterBottom
                    variant="subtitle2"
                    component="h5">
                    {name}
                  </Typography>
                  <Typography variant="body2" component="p">
                    Total Images: {images.length}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <GalleryModal
        open={open}
        details={modalInfo}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default CollectionPreview;
