import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Spinner2 from "../../Components/Spinner/spinner2.component";
import BlogPreview from "../../Components/BlogPreview/blogpreview.component";
import * as actions from "../../Redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50vw",
    justifyContent: "right",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    marginTop: "15px",
    float: "right",
    color: "darkgrey",
    backgroundColor: "rgba(0, 0, 0, 1)",
    width: 100,
    height: 50,
    fontSize: "1.3rem",
    borderRadius: "25px",
    border: "none",
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: "25px",
      cursor: "pointer",
    },
  },
  a: {
    textDecoration: "none",
    cursor: "pointer",
    color: "darkgrey",
  },
}));
const BlogPage = (props) => {
  const classes = useStyles();
  const { getBlogPosts, currentUser, getCurrentUser } = props;
  useEffect(() => {
    getCurrentUser();
    getBlogPosts();
  }, [getBlogPosts, getCurrentUser]);
  const { posts } = props.blog;

  return (
    <div className={classes.root}>
      {currentUser !== null && currentUser.owner ? (
        <div>
          <button className={classes.button}>
            <a href="/blog/new" className={classes.a}>
              New Post
            </a>
          </button>
          <br />
        </div>
      ) : null}

      {props.blog.posts.length >= 1 ? (
        <div>
          <BlogPreview posts={posts} />
        </div>
      ) : (
        <Spinner2 />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    blog: state.blog,
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, actions)(BlogPage);
