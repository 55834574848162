import React, { Fragment, useEffect, useState } from "react";
import Spinner from "../Spinner/spinner.component";
import Comments from "../Comments/comments.component";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actions from "../../Redux/actions";
import "./blogpost.css";

const useStyles = makeStyles((theme) => ({
  button: {
    border: "none",
    color: "#fff",
    "&:hover": {
      border: "1px solid #fff",
      borderRadius: 25,
    },
    "&:active": {
      color: "lightgrey",
      backgroundColor: "rgba(252, 136, 3, 0.5)",
      border: "1px solid grey",
      borderRadius: 25,
    },
    "&.Mui-disabled": {
      color: "rgba(161, 104, 104, 0.38)",
    },
  },
  closeIcon: {
    color: "#fff",
    float: "right",
    cursor: "pointer",
  },
  title: {
    color: "#fff",
    fontSize: "1.3em",
  },
  textInput: {
    resize: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    backgroundColor: theme.palette.background.modal,
    width: "50vw",
    height: "20vh",
    border: "1px solid grey",
    color: "lightgrey",
    "&:focus": {
      outline: "none",
    },
  },
  textInputDisabled: {
    resize: "none",
    paddingLeft: "10px",
    paddingTop: "10px",
    backgroundColor: theme.palette.background.modal,
    width: "50vw",
    height: "20vh",
    border: "1px solid rgba(161, 104, 104, 0.38)",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "rgba(161, 104, 104, 0.38)",
    },
  },
  hr: {
    borderTop: "1px solid rgba(255, 104, 104, 0.5);",
  },
  text: {
    color: "rgba(255, 255, 255, 0.75)",
  },
}));

// ObjectId("5fff84ae5a9a39009eae08ae")
const Post = (props) => {
  const { getBlogPost, post, getDate, auth, postComment } = props;
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (!post) {
      getBlogPost(props.match.params.id);
    }
    if (post) {
      setDate(getDate(post.createdAt));
    }
  }, [post, getBlogPost, getDate, props.match.params.id]);

  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };
  const clearComment = () => {
    setComment("");
  };
  const handleSubmit = () => {
    postComment({
      id: post._id,
      comment: {
        userId: auth._id,
        commentBody: comment,
      },
    });
    setComment("");
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={3}
        justify="flex-start"
        alignItems="center">
        {!post ? (
          <Fragment>
            <Spinner />
          </Fragment>
        ) : (
          <Fragment>
            <Grid item sm={10} md={8} lg={6} key={post._id}>
              <span className={classes.title}>{post.title}</span>
              <h5 style={{ marginTop: 0, color: "#fc8803" }}>
                {date ? (
                  <span>
                    {date.day} {date.month} {date.year} {date.hour}:
                    {date.minutes} {date.ampm}
                  </span>
                ) : null}
              </h5>{" "}
              <div className={classes.hr}></div>
              <span
                className={classes.text}
                dangerouslySetInnerHTML={{
                  __html: post.postBody,
                }}></span>
              <div className={classes.hr}></div>
              <h4 className={classes.text}>Comments:</h4>
              <Comments comments={post.comments} />
              <br />
              <div className={classes.hr}></div>
              <br />
              {auth ? (
                <Fragment>
                  <textarea
                    className={classes.textInput}
                    onChange={handleCommentInput}
                    placeholder="Care to share a comment?"
                    value={comment}
                  />
                  <br />
                  <Button className={classes.button} onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button className={classes.button} onClick={clearComment}>
                    Cancel
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <Tooltip title="You must be logged in to post a comment">
                    <span>
                      <textarea
                        disabled
                        className={classes.textInputDisabled}
                        placeholder="Care to share a comment?"
                      />

                      <br />

                      <Button disabled className={classes.button}>
                        Submit
                      </Button>

                      <Button disabled className={classes.button}>
                        Cancel
                      </Button>
                    </span>
                  </Tooltip>
                </Fragment>
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.user,
    post: state.blog.post,
    date: state.blog.date,
  };
};

export default connect(mapStateToProps, actions)(Post);
